import React from 'react';
import { graphql, Link } from 'gatsby';
import Rehype from 'rehype-react';

export default ({ data }) => {
  const doc = data.allMarkdownRemark.edges[0];

  function h1(props) {
    return (
      <h1 className="text-2xl font-normal pt-8 pb-4" {...props}>
        {props.children}
      </h1>
    );
  }

  function h2(props) {
    return (
      <h2 className="text-2xl font-normal py-4" {...props}>
        {props.children}
      </h2>
    );
  }

  function h3(props) {
    return (
      <h3 className="text-lg font-normal py-4" {...props}>
        {props.children}
      </h3>
    );
  }

  function p(props) {
    return (
      <p className="font-normal text-black py-2 relative" {...props}>
        {props.children}
      </p>
    );
  }

  function ol(props) {
    return <ol className="font-normal text-black py-4 relative" {...props} />;
  }

  const renderAst = new Rehype({
    createElement: React.createElement,
    components: {
      h1,
      h2,
      h3,
      p,
      ol
    }
  }).Compiler;

  return (
    <div className="container mx-auto">
      <div>{renderAst(doc.node.htmlAst)}</div>
      <div className='mx-auto flex justify-center py-4'>
        <a href="https://beian.miit.gov.cn/" target="__black"><div>©2022 沪ICP备17003425号-2</div></a>
        <a target="__black" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102004368" className="flex flex-column items-center justify-center px-8">
          <img className='w-4' src="//qhstaticssl.kujiale.com/image/png/1655783308239/icp-icon.png" alt="" /> 沪公网安备 31010102004368号
        </a>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "api-index" } } }) {
      edges {
        node {
          htmlAst
          fields {
            slug
          }
          frontmatter {
            type
          }
          tableOfContents
          id
          fileAbsolutePath
        }
      }
    }
  }
`;
